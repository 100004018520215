(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./libs/appadmin');
require('./libs/condition');
require('./libs/login');
require('./libs/pwdChecker');
require('./libs/select2opt');
require('./libs/wow');
require('./validation/password');
require('./switch');

},{"./libs/appadmin":2,"./libs/condition":3,"./libs/login":4,"./libs/pwdChecker":5,"./libs/select2opt":6,"./libs/wow":7,"./switch":8,"./validation/password":9}],2:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

// Recuperer element form au click sauvegader, recuperer url, ajax
// Switch to form
$('.chgapi').click(function (e) {
    e.preventDefault();
    var $tr = $(this).closest('tr');
    $tr.find('.txt').addClass('hide');
    $tr.find('.input').removeClass('hide');
});
// Save form and switch to text
$('.sveapi').click(function (e) {
    e.preventDefault();
    var $tr = $(this).closest('tr');
    var data = {
        apikey: $tr.find('.input-apikey').val(),
        publickey: $tr.find('.input-publickey').val(),
        mjquota: $tr.find('.input-mjquota').val(),
        mjdate: $tr.find('.input-mjdate').val(),
        cstid: $tr.attr('data-cstid')
    };
    $.post(window.location.href, data, function () {
        if (data.apikey === '') {
            data.apikey = '-';
        }
        if (data.publickey === '') {
            data.publickey = '-';
        }
        $tr.find('.txt-apikey').html(data.apikey);
        $tr.find('.txt-publickey').html(data.publickey);
        $tr.find('.txt-mjquota').html(data.mjquota);
        $tr.find('.txt-date').html(data.mjdate);
        $tr.find('.input').addClass('hide');
        $tr.find('.txt').removeClass('hide');
    });
});

$('.sveapi').click(function () {
    var client = $(this).closest('tr').find('.clname').text();
    var apikey = $(this).closest('tr').find('.input-apikey').val();
    var publickey = $(this).closest('tr').find('.input-publickey').val();
    var mjquota = $(this).closest('tr').find('.input-mjquota').val();
    var mjdate = $(this).closest('tr').find('.input-mjdate').val();
    var url = window.location.href;
    var properurl = url.slice(0, -14);
    properurl += '/api/api.php';
    var dataPost = 'ent=' + client + '&ak=' + apikey.replace(/\s+/g, '') + '&pk=' + publickey.replace(/\s+/g, '') + '&mjq=' + mjquota.replace(/\s+/g, '') + '&mjd=' + mjdate.replace(/\s+/g, '');
    $.ajax({
        url: properurl,
        type: 'POST',
        data: dataPost,
        dataType: 'text'
    });
});

$('.mailjetiframe > iframe').bind('load', function () {
    $('.sk-cube-grid').css('visibility', 'hidden');
});

$('#myTab').tab();

// client modification
$('.userchange').click(function (e) {
    e.preventDefault();
    var $tr = $(this).closest('tr');

    $tr.find('.txt').addClass('hide');
    $tr.find('.input').removeClass('hide');

    $tr.find('.usersaveinput').removeClass('hide');
});

// Save form and switch to text
$('.usersaveinput').click(function (e) {
    e.preventDefault();
    var $tr = $(this).closest('tr');
    var data = {
        user: $tr.find('.txt-user').html(),
        client: $tr.find('.txt-client').html(),
        droit: $tr.find('.input-droit').val(),
        mail: $tr.find('.input-mail').val()
    };
    console.log(data.user);
    $.post(window.location.href, data, function () {
        $tr.find('.txt-user').html(data.user);
        $tr.find('.txt-client').html(data.client);
        $tr.find('.txt-droit').html(data.droit);
        $tr.find('.txt-mail').html(data.mail);
    });
    $tr.find('.input').addClass('hide');
    $tr.find('.txt').removeClass('hide');
    $tr.find('.usersaveinput').addClass('hide');
    $tr.find('.userchange').removeClass('hide');
    var url = window.location.href;
    var properurl = url.slice(0, -14);
    properurl += '/api/apiuser.php';
    var dataPost = 'un=' + data.user + '&cl=' + data.client + '&dr=' + data.droit.replace(/\s+/g, '') + '&em=' + data.mail.replace(/\s+/g, '');
    $.ajax({
        url: properurl,
        type: 'POST',
        data: dataPost,
        dataType: 'text'
    });
});

$('.c100').mouseover(function () {
    $('.infobox').css('visibility', 'visible');
});

$('.c100').mouseout(function () {
    $('.infobox').css('visibility', 'hidden');
});

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],3:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

$('.popupcondition').on('scroll', function () {
    if ($(this).scrollTop() + $(this).innerHeight() + 1 >= $(this)[0].scrollHeight) {
        $('.yes').css('background-color', '#1586e4');
        $('.yes').prop('disabled', false);
        $('.yes').mouseover(function () {
            $(this).css('background-color', '#0c4f87');
        });

        $('.yes').mouseout(function () {
            $(this).css('background-color', '#1586e4');
        });
    }
});

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],4:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

// Because conditional comments does not work everytime, this function return the IE version or false if the browser is not IE
function isYourBrowserGarbage () {
    var userAgent = window.navigator.userAgent;
    var startingSlice = userAgent.indexOf('MSIE ') + 5; // 5 is for MISE and space because version number is just after
    var endingSlice = startingSlice;
    if (userAgent.indexOf('MSIE ') < 0) {
        return false;
    } else {
        while (userAgent.charAt(endingSlice) !== ';') {
            endingSlice++;
        }
        return parseInt(userAgent.slice(startingSlice, endingSlice));
    }
}

// Yeah because apparently linear gradient does not work in ie 9
if (isYourBrowserGarbage() === 9) {
    $('body').css('background', '#1976d2');
}

// Use the mouseparallax script with various option
$('#background').mouseParallax({moveFactor: 1});
$('#foreground').mouseParallax({moveFactor: 2});
$('#fore-foreground').mouseParallax({moveFactor: 2});
$('#fore-fore-foreground').mouseParallax({moveFactor: 2});
$('#fore-fore-fore-foreground').mouseParallax({moveFactor: 3});

// Handle the forms label animations
$('.form-signin input').on('click focus', function () {
    $(this).parent().addClass('active');
});

$('.form-signin input').on('blur', function () {
    var val = $(this).val().trim();

    if (val === '') {
        $(this).parent().removeClass('active');
    }
});

$('body').on('click', function (e) {
    if (e.target.tagName !== 'INPUT') {
        $('.form-signin input').each(function () {
            var val = $(this).val().trim();

            if (val === '') {
                $(this).parent().removeClass('active');
                $(this).val('');
            }
        });
    }
});

// If autofill (timeout necessary, because the navigator set it after this code is read)
setTimeout(function () {
    $('.form-signin input').each(function () {
        var val = $(this).val().trim();

        if (val !== '') {
            $(this).parent().addClass('active');
        }
    });
}, 500);

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],5:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

$('input[type=password]').keyup(function () {
    var pswd = $(this).val();
    if (pswd.length < 8) {
        $('#length').removeClass('valid').addClass('invalid');
    } else {
        $('#length').removeClass('invalid').addClass('valid');
    }
    if (pswd.match(/[A-z]/)) {
        $('#letter').removeClass('invalid').addClass('valid');
    } else {
        $('#letter').removeClass('valid').addClass('invalid');
    }
    if (pswd.match(/[A-Z]/)) {
        $('#capital').removeClass('invalid').addClass('valid');
    } else {
        $('#capital').removeClass('valid').addClass('invalid');
    }
    if (pswd.match(/\d/)) {
        $('#number').removeClass('invalid').addClass('valid');
    } else {
        $('#number').removeClass('valid').addClass('invalid');
    }
    if ((pswd.length >= 8) && pswd.match(/[A-z]/) && pswd.match(/[A-Z]/) && pswd.match(/\d/)) {
        $('#submgoodpwd').removeAttr('disabled');
    } else {
        $('#submgoodpwd').attr('disabled', 'disabled');
    }
}).focus(function () {
    $('#pswd_info').show();
}).blur(function () {
    $('#pswd_info').hide();
});

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],6:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

$('.select2').select2();

$('.senter').select2();

$('.right').select2();

$('.suser').select2({
    width: '25%'
});

$(window).on('beforeunload', function () {
    $(window).scrollTop(0);
});

$('input').map(function () {
    if ($(this).val() === '') {
        $(this).addClass('empty');
    }

    $(this).focus(function () {
        $(this).removeClass('empty');
    });

    $(this).blur(function () {
        if ($(this).val() === '') {
            $(this).addClass('empty');
        }
    });
});

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],7:[function(require,module,exports){
(function (global){(function (){
'use strict';

const WOW = global.WOW;

const wow = new WOW({
    mobile: false
});

wow.init();


}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],8:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;

$('.gourl').map(function () {
    $(this).change(function () {
        console.log($(this).val());
        window.location.href = $(this).val();
    });
});

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],9:[function(require,module,exports){
(function (global){(function (){
'use strict';

const $ = global.$;
const $pwd = $('.check input[type="password"]');

const search = {
    uppercase: /[A-Z]/,
    lowercase: /[a-z]/,
    special: /[^0-9a-zA-Z]/,
    digit: /[0-9]/,
    min: /.{6,}/
};

function validateEl (pwd, type, reg) {
    const $type = $('.' + type + ' i');

    if (pwd.match(reg)) {
        $type.addClass('fa-check');
        $type.removeClass('fa-times');
    } else {
        if ($type.hasClass('fa-check')) {
            $type.removeClass('fa-check');
            $type.addClass('fa-times');
        }
    }
}

function validate () {
    let pwd = $pwd.val();

    ['uppercase', 'lowercase', 'special', 'digit', 'min'].map(function (type) {
        validateEl(pwd, type, search[type]);
    });
}

if ($pwd.length > 0) {
    validate();

    $pwd.keyup(validate);
    $pwd.blur(validate);
}

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}]},{},[1]);
